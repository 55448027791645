// CartContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the Cart context
const CartContext = createContext();

// Create a provider component
export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [cartCount, setCartCount] = useState(0);
    const [checkoutSuccess, setCheckoutSuccess] = useState(false);

    const addToCart = (item) => {
        setCartItems((prevItems) => [...prevItems, item]);
        setCartCount((prevCount) => prevCount + 1);
    };

    const proceedToCheckout = () => {
        setCartItems([]);
        setCartCount(0);
        setCheckoutSuccess(true);
        setTimeout(() => setCheckoutSuccess(false), 5000); // Reset success after 5 seconds
    };

    return (
        <CartContext.Provider value={{ cartItems, cartCount, addToCart, proceedToCheckout, checkoutSuccess }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use the Cart context
export const useCart = () => useContext(CartContext);
