import React from 'react';
import BackIcon from './subcomponents/BackIcon';
import CartButtonComponent from './subcomponents/cartbutton';

export default function Header() {
    return (
        <div className="flex items-center justify-between gap-2 md:gap-4 relative m-4">
            <a href="/index.html" className="no-underline text-sm md:text-base">
                <BackIcon />
            </a>
            <CartButtonComponent />
        </div>
    );
}
