// ProductModal.js
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Dialog = styled.dialog`
    width: 80%;
    max-width: 500px;
    border: none;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    padding: 20px;
    background-color: white;
    position: relative;
    z-index: 1000;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

const AddToCartButton = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    width: 100%;
    text-align: center;
    display: block;
`;

// Define arrayBufferToBase64 function
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

const ProductModal = forwardRef(({ product, onClose, onAddToCart }, ref) => {
    return (
        <Dialog ref={ref}>
            <CloseButton onClick={onClose}>&times;</CloseButton>
            <h2>{product.name}</h2>
            <p><strong>Price:</strong> ${product.price}</p>
            <p><strong>Description:</strong> {product.description}</p>
            {product.image && product.image.data && (
                <img
                    src={`data:${product.image.contentType};base64,${arrayBufferToBase64(product.image.data.data)}`}
                    alt={product.name}
                    style={{ width: '100%', borderRadius: '5px', marginTop: '20px' }}
                />
            )}
            <AddToCartButton onClick={() => onAddToCart(product)}>
                Add to Cart
            </AddToCartButton>
        </Dialog>
    );
});

export default ProductModal;
