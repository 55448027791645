// BackIcon.js
import React from 'react';
import { ArrowBackOutline } from 'react-ionicons';

export default function BackIcon() {
    return (
        <ArrowBackOutline
            color={'#000000'}
            height="30px"
            width="30px"
            className="back-arrow"
        />
    );
}