import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ProductModal from './subcomponents/productModal';
import { useCart } from '../context/CartContext'; // Import the useCart hook

// Styled Components
const Container = styled.div`
    padding: 11px;
`;

const Heading = styled.h1`
    font-size: 24px;
    margin-bottom: 10px;
`;

const CategoryHeading = styled.h2`
    font-size: 20px;
    margin: 20px 0 10px;
`;

const CategoryItemContainer = styled.div`
    margin-bottom: 20px;
`;

const ProductList = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const ProductItem = styled.li`
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
        "image name price"
        "image description2 description3";
    gap: 10px;
    align-items: center;
    cursor: pointer;
`;

const ProductName = styled.h3`
    align-self: start;
    justify-self: start;
    grid-area: name;
    font-size: 20px;
`;

const ProductPrice = styled.p`
    grid-area: price;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    justify-self: end; /* Aligns the price to the far right */
`;

const ProductDescription = styled.p`
    grid-area: description2;
    font-size: 16px;
    color: #555;
    margin-top: 10px;
    justify-self: start;
`;

const ProductImage = styled.img`
    grid-area: image;
    max-width: 100%;
    height: 10rem;
    border-radius: 5px;
`;

// Helper function
export const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

// Main Component
const MainBody = ({ categories, products }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const dialogRef = useRef(null);
    const { addToCart } = useCart(); // Use the addToCart function from context

    // Group products by category
    const groupedProducts = categories.map(category => ({
        category,
        products: products.filter(product => product.category && product.category._id === category._id)
    }));

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleCloseModal = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
        setSelectedProduct(null);
    };

    const handleAddToCart = (product) => {
        addToCart(product); // Use the context's addToCart function
        handleCloseModal();
    };

    return (
        <Container>
            <Heading>Categories and Products</Heading>
            {groupedProducts.length > 0 ? (
                groupedProducts.map(({ category, products }) => (
                    <CategoryItemContainer key={category._id}>
                        <CategoryHeading>{category.name}</CategoryHeading>
                        {products.length > 0 ? (
                            <ProductList>
                                {products.map(product => (
                                    <ProductItem
                                        key={product._id}
                                        onClick={() => handleProductClick(product)}
                                    >
                                        <ProductName>{product.name}</ProductName>
                                        <ProductPrice>
                                            <strong>Price:</strong> ${product.price}
                                        </ProductPrice>
                                        <ProductDescription>
                                            <strong>Description:</strong> {product.description}
                                        </ProductDescription>
                                        {product.image && product.image.data && (
                                            <ProductImage
                                                src={`data:${product.image.contentType};base64,${arrayBufferToBase64(product.image.data.data)}`}
                                                alt={product.name}
                                            />
                                        )}
                                    </ProductItem>
                                ))}
                            </ProductList>
                        ) : (
                            <p>No products available for this category</p>
                        )}
                    </CategoryItemContainer>
                ))
            ) : (
                <p>No categories available</p>
            )}
            {selectedProduct && (
                <ProductModal
                    ref={dialogRef}
                    product={selectedProduct}
                    onClose={handleCloseModal}
                    onAddToCart={handleAddToCart} // Use context's addToCart function
                />
            )}
        </Container>
    );
};

export default MainBody;
