import React, { useRef, useState } from 'react';
import { useCart } from '../../context/CartContext';

export default function CartButtonComponent() {
    const dialogRef = useRef(null);
    const { cartItems, cartCount, proceedToCheckout } = useCart();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('cash');
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const handleOpenCartModal = () => {
        if (dialogRef.current) {
            dialogRef.current.showModal();
        }
    };

    const handleCloseCartModal = () => {
        if (dialogRef.current) {
            dialogRef.current.close();
        }
    };

    const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

    const validatePhone = (phone) => /^\d{10}$/.test(phone);

    const handleCheckout = () => {
        let hasError = false;

        if (!email || !validateEmail(email)) {
            setEmailError(true);
            hasError = true;
        } else {
            setEmailError(false);
        }

        if (!phone || !validatePhone(phone)) {
            setPhoneError(true);
            hasError = true;
        } else {
            setPhoneError(false);
        }

        if (!hasError) {
            proceedToCheckout();
            handleCloseCartModal();
        }
    };

    const totalAmount = cartItems.reduce((total, item) => total + item.price, 0);

    return (
        <div className="relative">
            <button
                className="bg-green-500 text-white py-4 px-8 text-center text-base leading-normal rounded relative"
                onClick={handleOpenCartModal}
            >
                Cart
                {cartCount > 0 && (
                    <span className="absolute top-[-10px] right-[-10px] bg-red-500 text-white rounded-full py-1 px-2 text-sm z-10">
                        {cartCount}
                    </span>
                )}
            </button>
            <dialog
                ref={dialogRef}
                className="w-11/12 max-w-lg border-none rounded-lg shadow-lg p-6 bg-white fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
            >
                <button
                    className="absolute top-2 right-2 text-2xl cursor-pointer"
                    onClick={handleCloseCartModal}
                >
                    &times;
                </button>
                <h2 className="text-xl mb-4">Your Cart</h2>
                <ul className="list-none p-0 mb-5">
                    {cartItems.length > 0 ? (
                        cartItems.map((item, index) => (
                            <li
                                key={index}
                                className="mb-3 pb-3 border-b border-gray-300 flex justify-between items-center"
                            >
                                <span>{item.name}</span>
                                <span>${item.price}</span>
                            </li>
                        ))
                    ) : (
                        <p>Your cart is empty.</p>
                    )}
                </ul>
                {cartItems.length > 0 && (
                    <>
                        <p className="text-lg font-bold text-right mt-2">
                            Total: ${totalAmount.toFixed(2)}
                        </p>
                        <div className="mb-4">
                            <label
                                className={`block text-sm mb-1 ${emailError ? 'text-red-500' : 'text-black'}`}
                            >
                                Email Address
                            </label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`w-full p-2 text-sm border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded`}
                            />
                        </div>
                        <div className="mb-4">
                            <label
                                className={`block text-sm mb-1 ${phoneError ? 'text-red-500' : 'text-black'}`}
                            >
                                Phone Number
                            </label>
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className={`w-full p-2 text-sm border ${phoneError ? 'border-red-500' : 'border-gray-300'} rounded`}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="block mb-2 font-bold">
                                Select Payment Method
                            </label>
                            <div className="mb-2">
                                <input
                                    type="radio"
                                    id="cash"
                                    name="payment"
                                    value="cash"
                                    checked={selectedPaymentMethod === 'cash'}
                                    onChange={() => setSelectedPaymentMethod('cash')}
                                    className="mr-2"
                                />
                                <label htmlFor="cash">Cash</label>
                            </div>
                            <div className="mb-2">
                                <input
                                    type="radio"
                                    id="credit_card"
                                    name="payment"
                                    value="credit_card"
                                    checked={selectedPaymentMethod === 'credit_card'}
                                    onChange={() => setSelectedPaymentMethod('credit_card')}
                                    className="mr-2"
                                />
                                <label htmlFor="credit_card">Credit Card</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="stripe"
                                    name="payment"
                                    value="stripe"
                                    checked={selectedPaymentMethod === 'stripe'}
                                    onChange={() => setSelectedPaymentMethod('stripe')}
                                    className="mr-2"
                                />
                                <label htmlFor="stripe">Stripe</label>
                            </div>
                        </div>
                        <button
                            className="bg-green-500 text-white py-2 px-4 rounded w-full text-lg"
                            onClick={handleCheckout}
                        >
                            Proceed to Checkout
                        </button>
                    </>
                )}
            </dialog>
        </div>
    );
}
