// App.js
import React from "react";
import './style/desktop.css';
import Header from './components/Header';
import MainBody from './components/MainBody';
import { CartProvider } from './context/CartContext';
import useFetch from './hooks/useFetch';

export default function App() {
    const { data, loading, error } = useFetch([
        'https://backend-waraqwalaimoon-5ad668e7acff.herokuapp.com/api/categories',
        'https://backend-waraqwalaimoon-5ad668e7acff.herokuapp.com/api/products/viewproducts'
    ]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    console.log('Fetched data:', data);

    if (!Array.isArray(data) || data.length !== 2) {
        return <div>Error: Unexpected data format</div>;
    }

    const [categoriesData, productsData] = data;

    const categories = categoriesData.data.categories || categoriesData.data;

    if (!categoriesData || categoriesData.status !== 'success' || !Array.isArray(categories)) {
        return <div>Error: Unexpected categories data format</div>;
    }

    const products = productsData.data.products;

    if (!productsData || productsData.status !== 'success' || !Array.isArray(products)) {
        return <div>Error: Unexpected products data format</div>;
    }

    return (
        <CartProvider>
            <div className="App">
                <Header />
                <MainBody
                    categories={categories}
                    products={products}
                />
            </div>
        </CartProvider>
    );
}
