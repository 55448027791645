import { useState, useEffect } from 'react';

const useFetch = (urls) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await Promise.all(urls.map(url => fetch(url)));
                const dataArr = await Promise.all(responses.map(res => res.json()));
                console.log('Fetched data:', dataArr); // Log the fetched data
                setData(dataArr);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };
        fetchData();
    }, []); // Empty dependency array to run only once

    return { data, loading, error };
};

export default useFetch;
